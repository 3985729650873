import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { clinicImpersonated } from "../../util/functions";
import IconCheckView from "../IconCheckView";

const useStyles = makeStyles(() => {
  return {
    fontFamilyAlmarai: {
      fontFamily: "Almarai-semi !important",
    },
    fontFamilyEina: {
      fontFamily: "Eina-semi !important",
    },
    fontFamilyHelvetica: {
      fontFamily: "Helvetica!important",
    },
    wrapper: {
      display: "flex",
      flexDirection: "column",
      gap: "0.5rem",
      borderRadius: "3px",
      flex: 1,
      padding: "1rem",
      backgroundColor: "#fff",
      boxShadow:
        "0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)",
      ["@media only screen and (max-width: 1170px) "]: {
        width: "100%",
        marginLeft: "0",
      },
    },
    typoColor: {
      color: "#637381",
    },
    headerTitle: {
      fontSize: "18px !important",
      letterSpacing: 0,
      lineHeight: "32px",
    },
    headerWrapper: {
      display: "flex",
      justifyContent: "space-between",
      margin: "0.2rem 0",
    },
    btn: {
      padding: 0,
      fontFamily: "Helvetica",
      fontSize: "11px !important",
      letterSpacing: 0,
      lineHeight: "13px",
      textAlign: "right",
      color: "#007ACE",
      "&:hover": {
        backgroundColor: "#FFF",
      },
    },
    bold: {
      fontWeight: "bold",
    },
    semiBold: {
      fontWeight: "600",
    },
    midTitle: {
      fontSize: "14px !important",
      letterSpacing: 0,
      lineHeight: "20px",
    },
    width: {
      width: "97%",
    },
  };
});
const PatientDataV2 = ({ userId, patientDataValue, action, clinic }) => {
  const classes = useStyles();
  const appointmentId = patientDataValue[0]?.data?.user_scan_info_id;
  let disable = true;
  const navigate = useNavigate();
  const [iconScreening, setIconScreening] = useState(null);
  const [iconInformation, setIconInformation] = useState(null);
  const [iconScans, setIconScans] = useState(null);
  const [iconXray, setIconXray] = useState(null);
  const [isNewUser, setIsNewUser] = useState(true);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const newUser = patientDataValue[0]?.data?.new;
    const screeningProvided = patientDataValue[0]?.data?.photos_provided;
    const questionsProvided = patientDataValue[0]?.data?.questions_provided;
    const xrayProvided = patientDataValue[0]?.data?.xray_provided;
    const scansProvided = patientDataValue[0]?.data?.sent_via_email || patientDataValue[0]?.data?.scans_provided;
    setIconScreening(screeningProvided);
    setIconInformation(questionsProvided);
    setIconXray(xrayProvided);
    setIconScans(scansProvided);
    setIsNewUser(newUser);
  }, []);

  const editHandler = () => {
    if (isNewUser) {
      navigate(
        `/dashboard/users/${userId}/appointments/${appointmentId}/edit-patient-data`
      );
    } else {
      navigate(`/dashboard/users/${userId}/edit-patient-data`);
    }
  };

  if (action === "Completed" || action === "Confirmed") {
    disable = false;
  }

  return (
    <Box className={classes.wrapper}>
      <Box className={`${classes.headerWrapper}`}>
        <Typography
          className={`${classes.headerTitle} ${classes.typoColor} ${
            classes.bold
          } ${
            i18n.language === "ar"
              ? classes.fontFamilyAlmarai
              : classes.fontFamilyEina
          }`}
        >
          {t("Patient-Data")}
        </Typography>
        <Button
          onClick={editHandler}
          disabled={disable}
          className={`${classes.btn} ${
            i18n.language === "ar"
              ? classes.fontFamilyAlmarai
              : classes.fontFamilyHelvetica
          }`}
        >
          {clinicImpersonated() ? t("View") : t("Edit")}
        </Button>
      </Box>
      <Box>
        {
          !clinic?.book_scan_info?.clinic?.is_mall_collaboration &&
          <Box
            className={`${classes.headerWrapper} ${
              i18n.language === "ar" ? classes.width : ""
            }`}
          >
            <Typography
              className={`${classes.semiBold} ${classes.typoColor} ${
                classes.midTitle
              } ${
                i18n.language === "ar"
                  ? classes.fontFamilyAlmarai
                  : classes.fontFamilyEina
              }`}
            >
              {t("Photos")}
            </Typography>
            <IconCheckView value={iconScreening} />
          </Box>
        }
        <Box
          className={`${classes.headerWrapper} ${
            i18n.language === "ar" ? classes.width : ""
          }`}
        >
          <Typography
            className={`${classes.semiBold} ${classes.typoColor} ${
              classes.midTitle
            } ${
              i18n.language === "ar"
                ? classes.fontFamilyAlmarai
                : classes.fontFamilyEina
            }`}
          >
            {t("Questions")}
          </Typography>
          <IconCheckView value={iconInformation} />
        </Box>
        <Box
          className={`${classes.headerWrapper} ${
            i18n.language === "ar" ? classes.width : ""
          }`}
        >
          <Typography
            className={`${classes.semiBold} ${classes.typoColor} ${
              classes.midTitle
            } ${
              i18n.language === "ar"
                ? classes.fontFamilyAlmarai
                : classes.fontFamilyEina
            }`}
          >
            {t("Scans")}
          </Typography>
          <IconCheckView value={iconScans} />
        </Box>
        {(!clinic?.book_scan_info?.clinic?.is_mall_collaboration && isNewUser) && (
          <Box
            className={`${classes.headerWrapper} ${
              i18n.language === "ar" ? classes.width : ""
            }`}
          >
            <Typography
              className={`${classes.semiBold} ${classes.typoColor} ${
                classes.midTitle
              } ${
                i18n.language === "ar"
                  ? classes.fontFamilyAlmarai
                  : classes.fontFamilyEina
              }`}
            >
              {t("X-Ray")}
            </Typography>
            <IconCheckView value={iconXray} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default PatientDataV2;
